import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const { isNavigationFailure, NavigationFailureType } = Router;
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  originalPush.call(this, location).catch((error) => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
      throw Error(error);
    }
  });
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/pages/FullLayoutPage.vue"),
      children: [
        {
          path: "/",
          redirect: "/Login",
        },
        {
          path: "/Login",
          name: "Login",
          component: () => import("@/pages/LoginPage.vue"),
          props: true,
        },
        {
          path: "/Enter_Email",
          name: "EnterEmail",
          component: () => import("@/pages/EnterEmailPage.vue"),
          props: true,
        },
        {
          path: "/Dashboard",
          name: "Dashboard",
          component: () => import("@/pages/DashboardPage.vue"),
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/",
    },
  ],
});

export default router;
