import Vue from "vue";
import App from "./App.vue";

Vue.config.devtools = false;
Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import axios from "./axios.js";
Vue.prototype.$http = axios;
const token = localStorage.getItem("accessToken");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  position: "bottom-left",
  timeout: 3000,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  bodyClassName: "custom-toast-class",
});

import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

import router from "./router";
Vue.use(router);

import LoadingPlugin from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(LoadingPlugin, {
  color: "#086efc",
  backgroundColor: "#c9c7c7",
  opacity: 0.5,
});

import uuid from "vue-uuid";
Vue.use(uuid);

import jwtDecode from "jwt-decode";

import Clipboard from "v-clipboard";
Vue.use(Clipboard);

import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

axios.interceptors.response.use(
  function (response) {
    if (response.data.logout == "true") {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      router.push({ name: "Login" });
    } else {
      if (localStorage.getItem("accessToken")) {
        const token = localStorage.getItem("accessToken");
        const token_broken = jwtDecode(token);
        if (Date.now() >= token_broken.exp * 1000) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          router.push({ name: "Login" });
        }
      }
    }
    return response;
  },
  function (error) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (
      error.response.status == 401 &&
      userInfo?.has_subscription == false &&
      userInfo.isExpired == true
    ) {
      const vm = new Vue();
      vm.$toast.error(
        "Your 7-day Free Trial period has ended!\nKindly subscribe to our plans to continue using NexLaw's features."
      );
      return Promise.reject(error.response);
    } else if (error.response.status == 401) {
      if (localStorage.getItem("accessToken")) {
        const token = localStorage.getItem("accessToken");
        const token_broken = jwtDecode(token);
        if (Date.now() >= token_broken.exp * 1000) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          router.push({ name: "Login" });
        }
      } else if (!localStorage.getItem("accessToken")) {
        localStorage.removeItem("userInfo");
        router.push({ name: "Login" });
      }
      const vm = new Vue();
      vm.$toast.error(error.response.data.message);
      return Promise.reject(error.response);
    } else if (error.response.status == 500) {
      if (localStorage.getItem("accessToken")) {
        const token = localStorage.getItem("accessToken");
        const token_broken = jwtDecode(token);
        if (Date.now() >= token_broken.exp * 1000) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          router.push({ name: "Login" });
        }
      } else if (!localStorage.getItem("accessToken")) {
        localStorage.removeItem("userInfo");
        router.push({ name: "Login" });
      }
      const vm = new Vue();
      vm.$toast.error(error.response.data.message);
      return Promise.reject(error.response);
    } else if (
      error.response.data.message ===
      "someone login by your account -- you are logged out"
    ) {
      if (localStorage.getItem("accessToken")) {
        const token = localStorage.getItem("accessToken");
        const token_broken = jwtDecode(token);
        if (Date.now() >= token_broken.exp * 1000) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          router.push({ name: "Login" });
        } else {
          const vm = new Vue();
          vm.$toast.error("Unauthorized access detected!");
          vm.$toast.error(
            "For security reasons, your current session is being logged out due to the detection of another login session."
          );
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          router.push({ name: "Login" });
        }
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userInfo");
        router.push({ name: "Login" });
      }
    } else if (error.response.data.message === "Unauthenticated.") {
      if (localStorage.getItem("accessToken")) {
        const token = localStorage.getItem("accessToken");
        const token_broken = jwtDecode(token);
        if (Date.now() >= token_broken.exp * 1000) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          router.push({ name: "Login" });
        } else {
          const vm = new Vue();
          vm.$toast.error(error.response.data.message);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userInfo");
          router.push({ name: "Login" });
        }
      } else {
        const vm = new Vue();
        vm.$toast.error(error.response.data.message);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userInfo");
        router.push({ name: "Login" });
      }
    } else if ("Validation error" === error.response.data.message) {
      const vm = new Vue();
      vm.$toast.error(
        error.response.data.message + " : " + error.response.data.data[0]
      );
      return Promise.reject(error.response);
    } else if (error.response.data.message === "No campaign found") {
      const vm = new Vue();
      vm.$toast.error(error.response.data.message);
    } else {
      if (typeof error.response.data.data == "object") {
        let error_show = "";
        error_show = error_show + error.response.data.message + " : ";
        for (const [value] of Object.entries(error.response.data.data)) {
          error_show = error_show + value + " ";
        }

        const vm = new Vue();
        vm.$toast.error(error_show);
      } else {
        const vm = new Vue();
        vm.$toast.error(error.response.data.message);
      }
    }
  }
);

window.Office.onReady(() => {
  new Vue({
    router,
    render: (h) => h(App),
    pinia,
  }).$mount("#app");
});
