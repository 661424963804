<template>
    <div id="app">
        <router-view :key="$route.path" class="view"></router-view>
    </div>
</template>

<script>
export default {
    name: 'App'
};
</script>

<style>
:root {
    --sidebar-bg-colour: #fff;
    --sidebar-item-hover: #dce8f6;
    --sidebar-item-active: #013d82;
    --light: #f1f5f9;
    --white: #fff;
    --primary: #0e80ec;
    --sidebar-width: 250px;
    --color-primary: #094890;
    --color-primary-light: #d9e6f5;
    --color-darkgreen: #183b1c;
    --color-muted: #6c757d;
    --color-darkgrey: #3c3c3c;
    --color-softblack: #1d1d1f
}

.color-primary {
    color: var(--color-primary) !important
}

.color-muted {
    color: var(--color-muted) !important
}

.color-darkgrey {
    color: var(--color-darkgrey) !important
}

#app,
.view,
body,
html {
    width: 100%;
    height: 100%
}

#app>.view {
    overflow-x: hidden
}

.text-white {
    color: #fff !important
}

.text-softblack {
    color: #1d1d1f !important
}

.btn {
    height: 38px
}

.btn-primary {
    border: 1px solid #094890 !important;
    background-color: #094890 !important;
    color: #fff;
    border-radius: 6px
}

.btn-primary:hover {
    background-color: #0e80ec !important;
    color: #fff
}

.btn-primary-outlined {
    border: 1px solid #094890 !important;
    background-color: #fff !important;
    border-radius: 6px !important;
    color: #094890 !important
}

.bg-blue {
    border: 1px none !important;
    background: #094890 !important;
    color: #fff !important;
    border-radius: 6px
}

.btn-primary-outlined:hover {
    background-color: #094890 !important;
    color: #fff !important
}

.btn-grey-outlined {
    border: 1px solid #bcbdbc !important;
    background-color: #fff !important;
    color: #888 !important
}

.btn-grey-outlined:hover {
    background-color: #efefef !important;
    color: #888 !important
}

.btn-grey-outlined.active {
    border: 1px solid #bcbdbc !important;
    background-color: #bcbdbc !important;
    color: #4a4a4a !important
}

.btn-warning-outlined {
    border: 1px solid #f08000 !important;
    background-color: #fff !important;
    color: #f08000 !important
}

.btn-warning-outlined:hover {
    background-color: #f9efe5 !important;
    color: #f08000 !important
}

.btn-warning-outlined.active {
    border: 1px solid #bcbdbc !important;
    background-color: #bcbdbc !important;
    color: #f08000 !important
}

.btn-arrow-icon {
    height: 38px;
    width: 38px;
    display: grid;
    place-items: center
}

.br-r {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important
}

.br-l {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-right: 0 !important
}

.br-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.btn-red {
    border: 1px solid #d21617 !important;
    background-color: #d21617 !important;
    color: #fff;
    border-radius: 6px
}

.btn-red:hover {
    border: 1px solid #ac1313 !important;
    background-color: #ac1313 !important;
    color: #fff;
    border-radius: 6px
}

.app-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%
}

#app {
    font-family: Inter, Roboto, Arial, "san-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    background: #fff;
    display: flex
}

@media only screen and (max-width: 768px) {

    a,
    b,
    li,
    p,
    small,
    span {
        line-height: 1.5rem
    }
}

#app ::-moz-selection {
    background: #60c0f7;
    color: #fff
}

#app ::selection {
    background: #60c0f7;
    color: #fff
}

::-webkit-scrollbar {
    width: 8px;
    height: 12px
}

::-webkit-scrollbar-track {
    box-shadow: transparent;
    border-radius: 5px
}

::-webkit-scrollbar-thumb {
    background: #2c8de0;
    border-radius: 5px;
    border-left: 3px solid #fff;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    cursor: pointer
}

.modal::-webkit-scrollbar-thumb {
    background: #2c8de0;
    border-radius: 5px;
    border-left: 3px solid #7f7f7f;
    border-top: 3px solid #7f7f7f;
    border-bottom: 3px solid #7f7f7f;
    cursor: pointer
}

::-webkit-scrollbar-thumb:hover {
    background: #479bea;
    border-left: 5px solid #479bea;
    border-top: 5px solid #479bea;
    border-bottom: 5px solid #479bea
}

@media screen and (max-width: 650px) {
    .row {
        line-height: 60%
    }
}

@media screen and (max-width: 575px) {
    .row {
        line-height: 50%
    }
}

button {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: transparent;
    outline: none;
    background: none
}

.table {
    margin: 0 !important
}

tr:last-child td {
    border-bottom-width: 0
}

.td-none {
    text-decoration: none;
    color: inherit
}

.text-primary {
    color: var(--color-primary) !important
}

.text-darkgrey {
    color: var(--color-darkgrey) !important
}

.text-darkgreen {
    color: #183b1c
}

.tooltip.b-tooltip-primary .tooltip-inner {
    background-color: #094890 !important
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
td,
th {
    font-family: Inter, Roboto, Arial, "san-serif"
}

.nexcard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    padding: 2rem
}

.vue-country-select .dropdown-list {
    overflow-x: hidden !important;
    width: 420px !important
}

.input-border {
    border: 1px solid #a4a4a4
}

.b-form-datepicker .dropdown-menu {
    background-color: #dce8f6
}

.b-form-datepicker .btn svg {
    fill: #2c8de0
}

.b-calendar-grid {
    box-shadow: none !important;
    border: 1px solid transparent !important;
    padding: 0 5px !important
}

.b-calendar-grid-weekdays .col {
    font-size: 12px !important
}

.b-form-datepicker .btn:hover {
    background-color: #c8c8c8
}

.b-form-btn-label-control.form-control>.btn:hover {
    background-color: #fff
}

.sidebar-right__popover {
    border: 1px solid transparent !important;
    background-color: #dce8f6 !important;
    translate: 0
}

.sidebar-right__popover.activity {
    translate: 0
}

.sidebar-right__popover .popover-body {
    padding: .6rem 1rem .4rem !important;
    color: #094890 !important;
    font-weight: 650;
    height: 42px
}

@media only screen and (max-width: 991px) {
    .sidebar-right__popover {
        display: none !important
    }
}

.custom__popover {
    border: 1px solid transparent !important;
    background-color: #dce8f6 !important;
    translate: 10px 0
}

.custom__popover .popover-body {
    padding: .6rem 1rem .4rem !important;
    color: #6c6c6c !important;
    font-weight: 600;
    height: 42px
}

.route__linkpath p {
    display: flex;
    align-items: center
}

.route__linkpath .btn-back {
    margin-left: -.3rem;
    margin-right: .3rem;
    font-size: 26px;
    cursor: pointer;
    padding: .1rem;
    border-radius: 50%;
    transition: .4s ease-out;
    color: var(--color-primary)
}

.route__linkpath .btn-back:hover {
    background-color: #e5e5e5;
    transition: .4s ease-out
}

.route__link {
    text-decoration: none !important;
    color: #6c757d
}

.route__link:hover {
    text-decoration: none !important;
    color: #094890 !important;
    text-decoration: underline;
    transition: all .5s ease-out
}

.us-0 {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.fw-600 {
    font-weight: 600
}

.card__inputbody {
    margin: 0 auto;
    padding: 2rem 0;
    max-width: 900px;
    display: flex;
    flex-direction: column
}

.modal-card__title {
    color: #094890;
    font-weight: 700;
    margin: 1rem 0
}

.card_inputgroup {
    display: flex;
    align-items: center;
    color: #094890;
    margin: 1rem 0
}

.card_inputgroup__title {
    min-width: 200px;
    font-weight: 700
}

.card_inputgroup__input {
    width: 100%
}

.card_inputgroup__title .material-icons-outlined {
    font-size: 15px;
    cursor: default
}

.card_inputgroup__radio {
    display: flex;
    justify-content: start;
    margin-right: -1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.card_inputgroup__radio .form-check .form-check-input {
    visibility: hidden
}

.card_inputgroup__radio .form-check .form-check-label {
    transform: translate(-24px);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: var(--white);
    border-radius: 20px;
    padding: .2rem .8rem;
    cursor: pointer
}

.card_inputgroup__radio .form-check .form-check-label:hover {
    background-color: var(--light);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    transition: all .3s ease-out
}

.card_inputgroup__radio .form-check .form-check-input:checked~.form-check-label {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: var(--white);
    transition: all .3s ease-out
}

@media only screen and (max-width: 991px) {
    .page-wrapper-xl {
        padding: 1.5rem !important
    }

    .page-wrapper-full,
    .page-wrapper-xl {
        height: calc(100vh - 68px);
        overflow-x: auto !important
    }

    .page-wrapper-full {
        padding: 1rem !important
    }

    .page-wrapper-md {
        padding: 1.5rem !important
    }

    .mw-900-lg {
        min-width: 900px
    }
}

.page-wrapper-full {
    position: relative;
    margin: 0 auto;
    padding: 1rem
}

.page-wrapper-xl {
    max-width: 1600px
}

.page-wrapper-lg,
.page-wrapper-xl {
    position: relative;
    margin: 0 auto;
    padding: 2rem 3rem 3rem
}

.page-wrapper-lg {
    max-width: 1200px
}

.page-wrapper-md {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem 2rem 3rem
}

.w-5 {
    width: 5% !important
}

.w-7-5 {
    width: 7.5% !important
}

.w-10 {
    width: 10% !important
}

.w-15 {
    width: 15% !important
}

.w-20 {
    width: 20% !important
}

.w-25 {
    width: 25% !important
}

.w-30 {
    width: 30% !important
}

.w-35 {
    width: 35% !important
}

.w-40 {
    width: 40% !important
}

.w-50 {
    width: 50% !important
}

.w-55 {
    width: 55% !important
}

.w-60 {
    width: 60% !important
}

.w-70 {
    width: 70% !important
}

.w-80 {
    width: 80% !important
}

.w-85 {
    width: 85% !important
}

.w-90 {
    width: 90% !important
}

.w-120px {
    width: 120px !important
}

.w-150px {
    width: 150px !important
}

.h-50 {
    height: 50% !important
}

.h-80 {
    height: 80% !important
}

.h-100 {
    height: 100% !important
}

.h-103 {
    height: 103% !important
}

.h-110 {
    height: 110% !important
}

.line-height-120 {
    line-height: 120%
}

.line-height-150 {
    line-height: 150%
}

.line-height-200 {
    line-height: 200%
}

.cursor-pointer {
    cursor: pointer !important
}

.b-form-radio-buttons {
    display: flex
}

.b-form-radio-buttons .btn-group>.btn {
    flex: none
}

.b-form-radio-buttons input {
    display: none
}

.b-form-radio-buttons .btn-primary {
    border: 1px solid var(--color-primary) !important;
    color: var(--color-primary) !important;
    background-color: var(--white) !important;
    border-radius: 20px !important;
    padding: .2rem .8rem !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important
}

.b-form-radio-buttons .btn-primary:not(:first-child) {
    margin-left: .5rem !important
}

.b-form-radio-buttons .btn-primary:hover {
    background-color: var(--light);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    transition: all .3s ease-out
}

.b-form-radio-buttons .btn-primary.active {
    background-color: var(--color-primary) !important;
    border: 1px solid var(--color-primary) !important;
    color: var(--white) !important;
    transition: all .3s ease-out !important
}

.screenshot_web__img-wrapper {
    -o-object-fit: cover;
    object-fit: cover;
    border: 1px solid #9a9a9a;
    background-color: #e8e8e8;
    border-radius: 10px;
    width: 100%;
    height: 100%
}

.screenshot_web__img-wrapper .expandable-image img {
    border-radius: 10px;
    max-height: 350px !important
}

.nextable td span img,
.table td span img {
    max-width: 150px;
    -o-object-fit: contain;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.h-38px {
    height: 38px !important
}

.border-blue {
    border: 1px solid #094890 !important
}

.search.input-group {
    padding-bottom: 0;
    height: 38px !important
}

.search.input-group .input-group-text {
    padding-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    height: 38px
}

.search.input-group input {
    border-left: 0
}

.search.input-group input:focus {
    border-color: #ced4da;
    border-left: 0;
    box-shadow: none
}

.search.input-group:focus-within {
    border-radius: .375rem
}

.ql-undo {
    font-size: 1.1rem;
    color: #343a40
}

.ql-star {
    font-size: 1.5rem;
    color: #ff8000
}

.ql-copy {
    font-size: 1.1rem;
    color: #343a40
}

.ql-size:hover:after {
    content: "Font Size"
}

.ql-font:hover:after,
.ql-size:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    font-weight: 400
}

.ql-font:hover:after {
    content: "Font"
}

.ql-header:hover:after {
    content: "Formatting";
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    font-weight: 400
}

.ql-bold:hover:after {
    content: "Bold"
}

.ql-bold:hover:after,
.ql-italic:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-italic:hover:after {
    content: "Italic"
}

.ql-underline:hover:after {
    content: "Underline"
}

.ql-strike:hover:after,
.ql-underline:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-strike:hover:after {
    content: "Strikethrough"
}

.ql-color:hover:after {
    content: "Text Color"
}

.ql-background:hover:after,
.ql-color:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-background:hover:after {
    content: "Text Highlight"
}

.ql-list[value=ordered]:hover:after {
    content: "Ordered List"
}

.ql-list[value=bullet]:hover:after,
.ql-list[value=ordered]:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-list[value=bullet]:hover:after {
    content: "Bullet List"
}

.ql-indent[value="+1"]:hover:after {
    content: "Indent"
}

.ql-indent[value="+1"]:hover:after,
.ql-indent[value="-1"]:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-indent[value="-1"]:hover:after {
    content: "Indent Reverse"
}

.ql-align.ql-icon-picker .ql-picker-label[aria-expanded=false]:hover:after {
    content: "Text Align";
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-align .ql-picker-item:first-child:hover:after {
    content: "Text Align Left"
}

.ql-align .ql-picker-item:first-child:hover:after,
.ql-align .ql-picker-item:nth-child(2):hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-align .ql-picker-item:nth-child(2):hover:after {
    content: "Text Align Center"
}

.ql-align .ql-picker-item:nth-child(3):hover:after {
    content: "Text Align Right"
}

.ql-align .ql-picker-item:nth-child(3):hover:after,
.ql-align .ql-picker-item:nth-child(4):hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-align .ql-picker-item:nth-child(4):hover:after {
    content: "Text Align Justify"
}

.ql-link:hover:after {
    content: "Add Hyperlink"
}

.ql-image:hover:after,
.ql-link:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-image:hover:after {
    content: "Add Image"
}

.ql-video:hover:after {
    content: "Add Video"
}

.ql-code-block:hover:after,
.ql-video:hover:after {
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-code-block:hover:after {
    content: "Code Block"
}

.ql-clean:hover:after {
    content: "Remove Styling";
    color: #fff;
    border-radius: 6px;
    padding: .2rem .5rem;
    background-color: #094890;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400
}

.ql-editor b,
.ql-editor p {
    font-size: 15px !important
}

.ql-editor h3 {
    font-size: 20px !important;
    font-weight: 600 !important
}

.ql-editor h2 {
    font-size: 24px !important;
    font-weight: 600 !important
}

.ql-editor h1 {
    font-size: 26px !important;
    font-weight: 600 !important
}

.no-wrap {
    white-space: nowrap !important
}

.k-progress-outer {
    padding-right: 0 !important
}

.nexcard-blue {
    border: 1px solid #094890;
    padding: 1rem;
    border-radius: 10px
}

.tabs__folderTabs {
    border-bottom: 2px solid #c4c4c4
}

.folderTab {
    padding: .3rem .5rem;
    transition: .2s ease-out;
    border-bottom: 2px solid transparent;
    translate: 0 2px
}

.folderTab:hover {
    color: #094890;
    border-bottom: 2px solid #094890;
    transition: .4s ease-out
}

.folderTab.active {
    background-color: #eee;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 2px solid #094890;
    transition: all .4s ease-out
}

.folderTab.active .folderTab__title {
    color: #094890 !important;
    transition: all .4s ease-out
}

.folderTab .folderTab__title {
    color: grey;
    font-weight: 600;
    transition: all .4s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.tabs__pillTabs {
    display: flex;
    margin-bottom: 1rem
}

.pillTab {
    display: flex;
    align-items: center;
    padding-top: .2rem;
    padding-bottom: .1rem;
    margin-right: .4rem;
    transition: .2s ease-out;
    border-radius: 20px;
    border: 1px solid #094890;
    background-color: #f4faff
}

.pillTab:hover {
    background-color: #dcefff;
    transition: .4s ease-out
}

.pillTab.active {
    background-color: #094890;
    transition: all .4s ease-out
}

.pillTab .pillTab__title {
    padding: 0 .5rem;
    color: #094890;
    font-weight: 500;
    font-size: 15px;
    transition: all .4s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.pillTab.active .pillTab__title {
    color: #fff;
    transition: all .4s ease-out
}

.br-rounded {
    border-radius: 50% !important
}

.float-right {
    float: right
}

.float-left {
    float: left
}

.float-center {
    margin: 0 auto
}

.font-weight-bold {
    font-weight: 600
}

.display-block {
    display: block
}

.icon-blue {
    color: #094890
}

.text-black {
    color: #000
}

.text-blue {
    color: #094890 !important
}

.text-blue-underline {
    color: #094890;
    text-decoration: underline;
    display: inline;
    cursor: pointer
}

@media screen and (max-width: 960px) {
    .table-card {
        overflow-x: auto
    }

    .table {
        width: 1000px !important
    }

    tbody {
        max-height: 500px !important
    }
}

@media screen and (max-width: 576px) {
    .table {
        width: 1000px !important
    }

    tbody {
        max-height: 800px !important
    }
}

.popover {
    will-change: auto !important
}

.m0 {
    margin-bottom: 0
}

.line-spacing {
    line-height: 1.1
}

.font-10 {
    font-size: 10px
}

.font-11 {
    font-size: 11px
}

.font-12 {
    font-size: 12px
}

.font-13 {
    font-size: 13px
}

.font-14 {
    font-size: 14px
}

.font-15 {
    font-size: 15px
}

.font-16 {
    font-size: 16px
}

.font-18 {
    font-size: 18px
}

.font-20 {
    font-size: 20px
}

.font-24 {
    font-size: 24px
}

.block {
    display: block
}

.contents-display {
    display: contents
}

.count_length {
    translate: -10px -25px
}

.credit__box {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: .5rem;
    width: 100%;
    border: 2px solid #094890;
    border-radius: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.orbit-spinner,
.orbit-spinner * {
    box-sizing: border-box
}

.orbit-spinner {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    perspective: 800px
}

.orbit-spinner .orbit {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%
}

.orbit-spinner .orbit:first-child {
    left: 0;
    top: 0;
    animation: orbit-spinner-orbit-one-animation 1.2s linear infinite;
    border-bottom: 3px solid #094890
}

.orbit-spinner .orbit:nth-child(2) {
    right: 0;
    top: 0;
    animation: orbit-spinner-orbit-two-animation 1.2s linear infinite;
    border-right: 3px solid #094890
}

.orbit-spinner .orbit:nth-child(3) {
    right: 0;
    bottom: 0;
    animation: orbit-spinner-orbit-three-animation 1.2s linear infinite;
    border-top: 3px solid #094890
}

@keyframes orbit-spinner-orbit-one-animation {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotate(0deg)
    }

    to {
        transform: rotateX(35deg) rotateY(-45deg) rotate(1turn)
    }
}

@keyframes orbit-spinner-orbit-two-animation {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotate(0deg)
    }

    to {
        transform: rotateX(50deg) rotateY(10deg) rotate(1turn)
    }
}

@keyframes orbit-spinner-orbit-three-animation {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotate(0deg)
    }

    to {
        transform: rotateX(35deg) rotateY(55deg) rotate(1turn)
    }
}

.circles-to-rhombuses-spinner,
.circles-to-rhombuses-spinner * {
    box-sizing: border-box
}

.circles-to-rhombuses-spinner {
    height: 15px;
    width: 95.625px;
    display: flex;
    align-items: center;
    justify-content: center
}

.circles-to-rhombuses-spinner .circle {
    height: 15px;
    width: 15px;
    margin-left: 16.875px;
    transform: rotate(45deg);
    border-radius: 10%;
    border: 3px solid #000;
    overflow: hidden;
    background: transparent;
    animation: circles-to-rhombuses-animation 1.2s linear infinite
}

.circles-to-rhombuses-spinner .circle:first-child {
    animation-delay: .15s;
    margin-left: 0
}

.circles-to-rhombuses-spinner .circle:nth-child(2) {
    animation-delay: .3s
}

.circles-to-rhombuses-spinner .circle:nth-child(3) {
    animation-delay: .45s
}

.custom-control-label {
    margin-left: 10px !important
}

@media only screen and (max-width: 320px) {
    .custom-control-label {
        margin-left: 6px !important
    }
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color) !important;
    background-color: #094890 !important;
    border-color: #094890 !important
}

.page-link {
    color: #094890 !important
}

@keyframes circles-to-rhombuses-animation {
    0% {
        border-radius: 10%
    }

    17.5% {
        border-radius: 10%
    }

    50% {
        border-radius: 100%
    }

    93.5% {
        border-radius: 10%
    }

    to {
        border-radius: 10%
    }
}

@keyframes circles-to-rhombuses-background-animation {
    50% {
        opacity: .4
    }
}

.new-styles .vs__dropdown-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: var(--vs-search-input-bg);
    border: 1px solid #dee2e6;
    border-radius: 5px;
    display: flex;
    padding: 0 0 1px;
    white-space: normal;
    font-size: .875rem;
}

.new-styles .vs__clear {
    display: none
}

.new-styles .vs__open-indicator {
    fill: #6f7377
}

.new-styles .vs__dropdown-menu {
    --vs-dropdown-min-width: 267px;
}

.h-20 {
    max-height: 200px !important;
    overflow: auto
}

.multiselect,
.multiselect__input,
.multiselect__single {
    min-height: 20px !important;
    font-size: 14px !important
}

.multiselect__placeholder {
    color: #706e6e !important
}

.multiselect__option--highlight,
.multiselect__tag {
    background: #094890 !important
}

.multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a !important
}

.multiselect__option--selected {
    background: #41b883 !important;
    color: #fff !important;
    font-weight: 700
}

.multiselect__tag-icon:after {
    font-size: 14px;
    color: #fff !important
}

.multiselect__tag-icon:hover {
    background: #0c5fbe !important;
    color: #fff !important
}

.reportrange-text {
    border: 1px solid #dee2e6 !important
}

.vue-daterange-picker {
    z-index: 9 !important
}

.vue-daterange-picker .form-control {
    line-height: 1.9 !important
}

.daterangepicker td.active:hover,
.vue-daterange-picker .daterangepicker td.active {
    background-color: #094890 !important;
    border-color: transparent;
    color: #fff
}

#clientAddLabel .multiselect {
    width: 117% !important
}

#clientAddLabel .multiselect,
.multiselect__input,
.multiselect__single {
    font-size: 16px !important
}

#clientAddLabel .multiselect__placeholder {
    color: #706e6e !important;
    font-size: 16px !important
}

#clientEditLabel .multiselect {
    width: 110% !important
}

#clientPill .pill-container {
    display: flex;
    flex-wrap: wrap
}

#clientPill .pill {
    display: inline-block;
    padding: .375rem .75rem;
    margin: .2rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #094890;
    border-radius: 10rem
}

#caseLawPill .pill {
    color: #00c441
}

#caseLawPill .pill,
#medCaseLawPill .pill {
    display: inline-block;
    padding: .375rem .75rem;
    margin: .2rem;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    border-radius: 10rem
}

#medCaseLawPill .pill {
    color: #ffae00
}

#lowCaseLawPill .pill {
    display: inline-block;
    padding: .375rem .75rem;
    margin: .2rem;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: #fc3c0d;
    background-color: #fff;
    border-radius: 10rem
}

#searchCaseLaw .input-group-text {
    color: #fff !important;
    background-color: #094890 !important;
    border: 1px solid #094890 !important
}

#customAgreementGenerator .input-group-text {
    background-color: #094890 !important;
    border: var(--bs-border-width) solid #094890 !important;
    border-radius: var(--bs-border-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

#customAgreementGenerator .bg-primary {
    --bs-bg-opacity: 1;
    background-color: #094890 !important
}

.vue-slider-dot-tooltip-inner {
    border-color: #094890 !important;
    background-color: #094890 !important
}

.input-tel__input {
    transition-duration: .3s;
    width: 100.1% !important;
    padding: 0 12px;
    margin-left: -1px
}

.country-selector__input,
.input-tel__input {
    cursor: pointer;
    background-color: #fff;
    position: relative;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #dee2e6 !important;
    font-size: 16px !important;
    font-family: Inter, Roboto, Arial, "san-serif" !important;
    z-index: 0;
    height: 40px;
    min-height: 40px
}

.country-selector__input {
    width: 100%;
    padding-right: 22px;
    padding-left: 8px;
    color: #000
}

.country-selector__toggle {
    position: absolute;
    right: 5px;
    top: calc(50% - 14px) !important;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    text-align: center;
    display: inline-block;
    cursor: pointer;
    height: 24px
}

.vue-phone-number-input .select-country-container {
    flex: 0 0 140px !important;
    width: 140px !important;
    min-width: 140px !important;
    max-width: 140px !important
}

.input-tel__label {
    left: 11px !important
}

.country-selector__label,
.input-tel__label {
    position: absolute;
    top: 2px !important;
    cursor: pointer;
    transform: translateY(25%);
    opacity: 0;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    font-size: 11px;
    color: #747474
}

.country-selector__label {
    left: 10px !important
}

#statesFilter .multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
    background: transparent !important;
    color: #a6a6a6
}

.title-sizing {
    font-weight: 700 !important
}

#activity-sidebar .b-sidebar-header {
    margin-left: 12px;
    display: flex;
    flex-direction: row-reverse
}

#activity-sidebar .b-sidebar-header button {
    margin-right: 0;
    margin-left: auto
}

#longLine {
    width: 60% !important
}

.form-control::file-selector-button {
    background-color: rgba(159, 159, 160, .6039215686274509);
    border: 1px solid rgba(159, 159, 160, .6039215686274509);
    border-radius: 4px 0 0 4px;
    cursor: pointer
}

.Vue-Toastification__toast-body.custom-toast-class {
    font-size: 0.875rem;
}
</style>