import axios from 'axios';

let baseUrl = '';

if (location.href.includes('microsoft.plugin.uat.nexlaw.ai')) {
    baseUrl = 'https://app.nexmind.nexodn.com/api/';
} else if (location.href.includes('microsoft.plugin.nexlaw.ai')) {
    baseUrl = 'https://api.platform.nexmind.ai/api/';
} else {
    baseUrl = 'https://app.nexmind.nexodn.com/api/';
}

export default axios.create({
    baseURL: baseUrl
});